export class Translator {
    readonly _translations: Map<String, String>;
    constructor(lang: string | undefined) {
        this._translations = new Map(Object.entries(require('./' + (lang ?? defaultLang) + '.json')))
    }

    t(key: K) {
        return this._translations.get(key) ?? key
    }

    pageName(pageKey: string) {
        return this._translations.get(pageKey) ?? pageKey
    }
}

export enum K {
    // general
    button_cancel = "button_cancel",
    button_ok = "button_ok",
    error = "error",
    no_translation = "no_translation",

    // sections
    about = "about",
    home = "home",
    introduction = "introduction",

    // subscribe
    subscribe_body = "subscribe_body",
    subscribe_button = "subscribe_button",
    subscribe_email = "subscribe_email",
    subscribe_nav = "subscribe_nav",
    subscribe_thanks = "subscribe_thanks",
    subscribe_title = "subscribe_title",
    
    // comment
    comment_body = "comment_body",
    comment_button = "comment_button",
    comment_content = "comment_content",
    comment_email = "comment_email",
    comment_nav = "comment_nav",
    comment_thanks = "comment_thanks",
    comment_title = "comment_title",

    // contact
    contact_body = "contact_body",
    contact_button = "contact_button",
    contact_content = "contact_content",
    contact_email = "contact_email",
    contact_thanks = "contact_thanks",
    contact_title = "contact_title",

    // generic page
    subsections = "subsections",
    subsections_in_section = "subsections_in_section",
    show_all_article = "show_all_article",
    discussion = "discussion",
    discussion_comment = "discussion_comment",
    discussion_reply = "discussion_reply",

    // timeline
    timeline = "timeline",
    timeline_in_section = "timeline_in_section",
    timeline_description = "timeline_description",
    timeline_discussion_badge = "timeline_discussion_badge",
    timeline_discussion_article = "timeline_discussion_article",
    timeline_discussion_this_article = "timeline_discussion_this_article",
    timeline_edit_badge = "timeline_edit_badge",
    timeline_edit_article = "timeline_edit_article",
    timeline_edit_this_article = "timeline_edit_this_article",
    timeline_new_badge = "timeline_new_badge",
    timeline_new_article = "timeline_new_article",
    timeline_new_this_article = "timeline_new_this_article",

    // fake
    fake_last_with_no_comma = "fake_last_with_no_comma"
}

export const supportedLanguages = ['en', 'pl']
export const defaultLang = 'en'
