import { Route, Navigate, useLocation, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Layout from './Layout';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Unsubscribe from './pages/Unsubscribe';
import { GenericPage } from "./pages/GenericContent";
import About from "./pages/About";
import { defaultLang, supportedLanguages } from "./translations/translator";

function App() {
  function loadPageContentWithFallback(lang: string, pathAfterLang: string) {
      return import('./pages/content/'+lang+'/'+pathAfterLang+'.json')
        .catch(e => import('./pages/content/en/'+pathAfterLang+'.json'))
  }
  return createBrowserRouter(createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to={getBrowserLang()} replace={true} />} />
      <Route path="/:lang" element={<LanguageSwitchingLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'About')} />
        <Route path="happy_society" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Happy_Society')} />
        <Route path="https_s" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Https_S')} />
        <Route path="human_life" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Human_Life')} />
        <Route path="introduction" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Introduction')} />
        <Route path="technical_curiosities" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Technical_Curiosities')} />
        <Route path="unsubscribe" element={<Unsubscribe />}/>
        <Route path="why_privacy" element={<GenericPage />} loader={({params}) => loadPageContentWithFallback(params.lang ?? 'en', 'Why_Privacy')} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </>
  ));
}

function LanguageSwitchingLayout() {
  const path = useLocation().pathname
  return(
    <>
      {path.startsWith('/int/') ?
          <Navigate to={"/" + getBrowserLang() + path.substring(4)} replace={true} />
        : !supportedLanguages.includes(path.substring(1,3)) ?
          <Navigate to={"/"+defaultLang} replace={true} />
        :
          <Layout />
      }
    </>
  )
}

function getBrowserLang() {
  return new Intl.Locale(navigator.language ?? defaultLang).language
}

export default App;
