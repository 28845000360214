import { useState } from "react";
import { Link } from "react-router-dom";
import { postForm } from "../utils";

function Unsubscribe() {
    function sendUnsubscribe(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault()
      const data = new FormData(e.currentTarget)
      setFailure(false)
      setSuccess(false)
      postForm('/api/unsubscribe', data, () => setSuccess(true), () => setFailure(true))
    }

    const [isSuccess, setSuccess] = useState(false)
    const [isFailure, setFailure] = useState(false)

    return (
      <>
        <h1>
            You are about to unsubscribe
        </h1>
        <p>We are sorry to see you go. If you have any comments or suggestions, don't hesitate to leave us a comment <Link to="../About">here</Link></p>
        <form onSubmit={sendUnsubscribe}>
          <label> Please provide your email:
            <input type="text" name="email" />
          </label>
          <button type="submit">Unsubscribe</button>
        </form>
        {isSuccess && <p>You have been successfully unsubscribed</p>}
        {isFailure && <p>There was an error while sending the request</p>}
      </>
    );
}
  
export default Unsubscribe;
