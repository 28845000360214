import banner from './images/banner.jpg';
import flag_en from './images/flag-en.svg';
import flag_pl from './images/flag-pl.svg';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { K, Translator } from './translations/translator';
import { Button, Col, Container, Dropdown, Form, Modal, Row, Stack } from 'react-bootstrap';
import { postForm } from './utils';
import { FadeLine } from './CommonComponents';

function Layout() {
  const {lang} = useParams()
  const t = new Translator(lang)
  const {pathname} = useLocation()

  const [showSubscribeModal, setShowSubscribeModal] = useState(false)

  useEffect(() => {
    document.documentElement.scrollTo({top: 0, left: 0});
  }, [pathname]);

  function getCurrentFlag() {
    switch(lang) {
      case "en": return flag_en;
      case "pl": return flag_pl;
      default: return flag_en;
    }
  }

  function LangItem({shortcut, flag}: {shortcut: string, flag: string}) {
    const navigate = useNavigate()
    return(
      <Dropdown.Item onClick={()=>navigate('/'+shortcut+pathname.substring(3))} className='p-0'>
        <Stack direction='horizontal' gap={2}>
          <img className="Flag-img" src={flag} alt={"language "+shortcut} />
          <div className="ms-auto text-uppercase">{shortcut}</div>
        </Stack>
      </Dropdown.Item>
    )
  }

  function SubscriptionModal() {
    const [subscribeEmail, setSubscribeEmail] = useState("")
    const [showForm, setShowForm] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [invalid, setInvalid] = useState(false)

    function subscribeSend() {
      if (subscribeEmail.includes("@")) {
        const data = new FormData()
        data.append('email', subscribeEmail)
        data.append('lang', lang ?? "en")
        setShowForm(false)
        postForm('/api/subscribe', data, () => setShowSuccess(true), () => setShowError(true))
      } else {
        setInvalid(true)
      }
    }

    return (
      <Modal show={showSubscribeModal} backdrop="static" onHide={()=>setShowSubscribeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t.t(K.subscribe_title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showForm && <>
            <p>{t.t(K.subscribe_body)}</p>
            <Form.Group>
              <Form.Label>{t.t(K.subscribe_email)}</Form.Label>
              <Form.Control isInvalid={invalid} type="email" placeholder="email@example.com" onChange={(e) => setSubscribeEmail(e.target.value)} />
            </Form.Group>
          </>}
          {showSuccess &&
            <p>{t.t(K.subscribe_thanks)}</p>}
          {showError &&
            <p>{t.t(K.error)}</p>}
        </Modal.Body>
        <Modal.Footer>
          {showForm && <>
            <Button variant='secondary' onClick={()=> setShowSubscribeModal(false)}>{t.t(K.button_cancel)}</Button>
            <Button variant='primary' onClick={() => subscribeSend()}>{t.t(K.subscribe_button)}</Button>
          </>}
          {(showSuccess || showError) &&
            <Button variant='primary' onClick={() => setShowSubscribeModal(false)}>{t.t(K.button_ok)}</Button>}
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <div style={{minHeight: "100vh", minWidth: "20em", position:"relative"}}>
      <header className='mb-3'>
        <Link className="Banner" to={'/'+lang}>
            <img src={banner} alt="banner" />
        </Link>

        <Container className='Navigation-bar bg-black' fluid>
          <Row>
            <Col sm className='d-flex'>
              <Stack direction='horizontal' gap={4} className='pb-3 pt-1 mx-auto'>
                <Link to={'/'+lang} className='ms-auto'>{t.t(K.home)}</Link>
                <Link to={'/'+lang+'/Introduction'}>{t.t(K.introduction)}</Link>
                <Link to={'/'+lang+'/About'} className='me-auto'>{t.t(K.about)}</Link>
              </Stack>
            </Col>
            <Col sm>
              <Stack direction='horizontal' gap={4} className='pb-3 pt-1'>
                <a href="/" className='ms-auto' onClick={(e)=>{e.preventDefault();setShowSubscribeModal(true)}}>{t.t(K.subscribe_nav)}</a>
                <a href={"/feed/"+lang} className=''>RSS</a>
                <div className='me-auto'>
                  <Dropdown aria-label='language selection'>
                    <Dropdown.Toggle className='p-1'>
                      <img src={getCurrentFlag()} className="Flag-img" alt="current language" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-1'>
                      <LangItem shortcut="en" flag={flag_en} />
                      <LangItem shortcut="pl" flag={flag_pl} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Stack>
            </Col>
          </Row>
        </Container>

        <FadeLine />
      </header>

      <SubscriptionModal />

      <div className="Page">
        <Outlet />
      </div>

      <div className='pt-4' />
      <div className='position-absolute bottom-0'>
        <FadeLine inverted={true} />
      </div>
    </div>
  );
}

export default Layout;
