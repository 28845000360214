import { Link } from "react-router-dom";

function NotFound() {
    return (
      <>
        <h1>
            Sorry, page not found.
        </h1>
        <Link to="/">Go back to the main page</Link>
      </>
    );
}
  
export default NotFound;