import { Alert, Badge, Button, Col, Collapse, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import fade from './images/fade.png';
import { K, Translator } from './translations/translator';
import Timeline from './external_vertical-timeline-component-for-react/Timeline';
import TimelineItem from './external_vertical-timeline-component-for-react/TimelineItem';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { postForm } from './utils';

export function Breadcrumbs({lang, tree_prefix} : {lang: string, tree_prefix: string}) {
    const t = new Translator(lang)

    function parentArticles(){
        const allTree = tree_prefix.split('/');
        return tree_prefix.split('/').slice(1, allTree.length - 1)
    }

    return (
        <div className="Page-section" style={{color: "#777"}}>
            <span key={-1} style={{display: "inline-block"}}>
                <Link to={"../"} style={{color: "#777"}}>{t.t(K.home)}</Link>
            </span>
            {parentArticles().map((o, i) =>
                    <span key={i} style={{display: "inline-block"}}>
                            &nbsp; &gt;&gt; &nbsp; 
                            <Link to={"../"+o} style={{color: "#777"}}>{t.pageName(o)}</Link>
                    </span>
            )}
            <span key={-2} style={{display: "inline-block"}}>
                &nbsp; &gt;&gt; &nbsp; 
                {t.pageName(tree_prefix.split('/').pop() || '')}
            </span>
        </div>
    )
}

export function ArticlesTimeline({lang, tree_prefix} : {lang: string, tree_prefix: string}) {
    const t = new Translator(lang)
    const timeline = (require('./pages/timelines/' + lang + '-timeline.json') as ArticleTimeline).timeline
        .filter(e => e.tree_location.startsWith(tree_prefix))

    function RenderEventBadge({eventType}: {eventType: string}) {
        switch(eventType) {
            case "CREATE":
                return <Badge className="me-2 bg-primary text-uppercase">{t.t(K.timeline_new_badge)}</Badge>
            case "EDIT":
                return <Badge className="me-2 bg-info text-uppercase">{t.t(K.timeline_edit_badge)}</Badge>
            case "DISCUSSION":
                return <Badge className="me-2 bg-secondary text-uppercase">{t.t(K.timeline_discussion_badge)}</Badge>
            default:
                return <></>
        }
    }

    return (
        <>
            {timeline.length > 0 &&
                <div className="mt-4 ms-1">
                    <h2 className="Page-section-header">{t.t(K.timeline)}{tree_prefix.length > 1 && <> {t.t(K.timeline_in_section)}</>}</h2>
                    {tree_prefix.length > 1 && <p className="Page-section-description">({t.t(K.timeline_description)})</p>}
                    <Timeline>
                        {timeline.map((e, i) =>
                            <TimelineItem key={i} dateText={e.title} link={e.link}
                                dateStyle={e.event_type==="CREATE" ? {backgroundColor: "#222"} : {}}
                                dateInnerStyle={e.event_type==="CREATE" ? {} : {color: "#777", fontWeight: 'normal'}}
                                style={e.event_type==="CREATE" ? {} : {color: "#777", fontWeight: 'normal'}} >
                                <Stack gap={3} direction='horizontal'>
                                    <i>{e.created}</i>
                                    <RenderEventBadge eventType={e.event_type} />
                                </Stack>
                                <div>{e.description}</div>
                            </TimelineItem>
                        )}
                    </Timeline>
                </div>}
        </>
    )
}

export function Subsections({sections, lang, path}: {sections: InternalLink[], lang: string, path: string}) {
    const t = new Translator(lang)
    return (
        <>
            {sections.length > 0 &&
                <div className='py-3'>
                    <h2 className="Page-section-header Subsections-title mb-4">{t.t(K.subsections)}{path.length > 4 && <> {t.t(K.subsections_in_section)}</>}</h2>
                    <Container>
                        <Row>
                            {sections.map((s, i) =>
                                <Col key={i} sm>
                                    <Stack direction="horizontal">
                                        <Link to={s.location} className="p-3 ms-auto me-auto mt-1 btn text-uppercase">{s.text}</Link>
                                    </Stack>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </div>
            }
        </>
    )
}

export function SubsectionsPagePart({subsections, lang, path}: {subsections: InternalLink[], lang: string, path: string}) {
    return (
        <>
            <FadeLine inverted={true} />
            <div className='Subsections-page-part'>
                {subsections && <>
                    <Subsections sections={subsections} lang={lang} path={path} />
                </>}
            </div>
            <FadeLine />
        </>
    )
}

export function LeaveCommentSection({lang, path}: {lang: string, path: string}) {
    const t = new Translator(lang)
    const [showCommentModal, setShowCommentModal] = useState(false)
    const [commentEmail, setCommentEmail] = useState("")
    const [commentContent, setCommentContent] = useState("")
    const [showForm, setShowForm] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [commentInvalid, setCommentInvalid] = useState(false)

    function postComment() {
        if (commentContent.length > 0) {
            const data = new FormData()
            data.append('email', commentEmail)
            data.append('lang', lang)
            data.append('article', path)
            data.append('comment', commentContent)
            setShowForm(false)
            postForm('/api/comment', data, () => setShowSuccess(true), () => setShowError(true))
        } else {
            setCommentInvalid(true)
        }
    }

    return (
        <>
            <Stack direction="horizontal">
                    <Button className="mx-auto btn-light px-5 mt-4" onClick={() => setShowCommentModal(true)}>{t.t(K.comment_nav)}</Button>
            </Stack>
            <Modal show={showCommentModal} backdrop="static" onHide={()=>setShowCommentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t.t(K.comment_title)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {showForm && <>
                    <p>{t.t(K.comment_body)}</p>
                    <Form.Group>
                        <Form.Label>{t.t(K.comment_email)}</Form.Label>
                        <Form.Control type="email" placeholder="email@example.com" onChange={(e) => setCommentEmail(e.target.value)} />
                    </Form.Group>
                </>}
                {(showForm || showError) &&
                    <Form.Group>
                        <Form.Label>{t.t(K.comment_content)}</Form.Label>
                        <Form.Control isInvalid={commentInvalid} as="textarea" rows={6} disabled={showError} placeholder="..." onChange={(e) => setCommentContent(e.target.value)} value={commentContent} />
                    </Form.Group>}
                {showSuccess &&
                    <p>{t.t(K.comment_thanks)}</p>}
                {showError &&
                    <p>{t.t(K.error)}</p>}
                </Modal.Body>
                <Modal.Footer>
                {showForm && <>
                    <Button variant='secondary' onClick={()=> setShowCommentModal(false)}>{t.t(K.button_cancel)}</Button>
                    <Button variant='primary' onClick={() => postComment()}>{t.t(K.comment_button)}</Button>
                </>}
                {(showSuccess || showError) &&
                    <Button variant='primary' onClick={() => setShowCommentModal(false)}>{t.t(K.button_ok)}</Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export function GenericContent({data, lang, path, comments = true, noCollapse = false} : {data: PostData, lang: string, path: string, comments?: boolean, noCollapse?: boolean}) {
    const t = new Translator(lang)
    const [showContent, setShowContent]
        = useState(noCollapse || !data.intro || !data.subsections || data.subsections.length === 0 || !data.content || data.content.length === 0)

    return (
        <>
            <div className="Page-section">
                {data.lang !== lang &&
                    <Alert variant="primary">{t.t(K.no_translation)}</Alert>}

                <h1>{data.title}</h1>

                {data.intro && <p className="fw-bold">{data.intro}</p>}

                <Collapse in={showContent}>
                    <div>
                        {data.content.map((o, i) => 
                            <div key={i}><RenderEntry entry={o} /></div>
                            )}

                        {comments && <>
                            <LeaveCommentSection lang={lang} path={path} />
                        </>}
                        {data.discussion && data.discussion.length > 0 && <>
                            <h2 className='mt-3'>{t.t(K.discussion)}</h2>
                            {data.discussion.map((o, i) =>
                                <div key={i} className='Quote mx-4 my-2 px-3 py-2'>
                                    <div style={{color: "#777", fontWeight: "bold", fontSize: "0.7em"}}>{t.t(K.discussion_comment)}</div>
                                    <div><i>
                                        {o.commentParagraphs.map((commentParagraph, paragraphNumber) =>
                                        <p key={paragraphNumber}>{commentParagraph}</p>)}
                                        </i></div>
                                    <div style={{color: "#777", fontWeight: "bold", fontSize: "0.7em"}}>{t.t(K.discussion_reply)}</div>
                                    <div>
                                        {o.responseParagraphs.map((responseParagraph, paragraphNumber) =>
                                        <p key={paragraphNumber}>{responseParagraph}</p>)}
                                    </div>
                                </div>
                            )}
                        </>}
                    </div>
                </Collapse>

                {!showContent && <>
                    <Button onClick={() => setShowContent(true)}>{t.t(K.show_all_article)}</Button>
                </>}
            </div>
        </>
    )
}

export function FadeLine({inverted = false}: {inverted?: boolean}) {
    return (
        <img className={"Fade-line" + (inverted ? " Inverted" : "")} src={fade} alt="" />
    )
}

function RenderEntry({entry}: {entry: Entry}) {
    switch(entry.type){
        case "h2":
            return <h2 className='mt-4'>{entry.value as string}</h2>
        case "h3":
            return <h3 className='H3 mt-3'>{entry.value as string}</h3>
        case "p":
            return <p className='mb-1 mt-3'>{entry.value as string}</p>
        case "li":
            return <p className='mb-1 mt-0 ms-3'>{entry.value as string}</p>
        case "ai": // internal link
            return (
                <Stack direction="horizontal">
                    <Link to={(entry.value as InternalLink).location} className='Internal-link mx-auto px-2 text-bold'>
                        {(entry.value as InternalLink).text}
                    </Link>
                </Stack>)
        case "ae": // external link
            return <a className='ms-3' href={(entry.value as InternalLink).location}>{(entry.value as InternalLink).text}</a>
        case "quotes": // multiple quotations next to each other
            return (
                <blockquote className='Quote blockquote mx-4 my-2 px-3 py-2 fst-italic'>
                    {(entry.value as Quote[]).map((quote, i) =>
                        <div key={i}>
                            {quote.text}
                            <footer className='blockquote-footer ms-3 my-0 fst-normal'>
                                {quote.author}
                            </footer>
                        </div>
                    )}
                </blockquote>
            )
        case "hr":
            return <hr className='border-2 m-5'/>
        default:
            return <></>
    }
}

export type PostData = {
    location: string
    title: string
    lang: string
    intro: string
    content: Entry[]
    discussion: Comment[]
    subsections: InternalLink[]
}

export type Entry = {
    type: string
    value: string | InternalLink | Quote[]
}

type InternalLink = {
    text: string
    location: string
}

type Quote = {
    text: string
    author: string
}

type Comment = {
    commentParagraphs: string[]
    responseParagraphs: string[]
}

export type ArticleTimeline = {
    timeline: ArticleEvent[]
}

export type ArticleEvent = {
    link: string
    title: string
    description: string
    author: string
    created: string
    event_type: string
    tree_location: string
}


