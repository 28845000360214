import { useParams } from "react-router-dom";
import { ArticlesTimeline, GenericContent, PostData, SubsectionsPagePart } from "../CommonComponents";

function Home() {
  const {lang} = useParams()
  const content = require('./content/' + lang + '/Home.json') as PostData
  return (
    <>
      <GenericContent data={content} lang={lang ?? "en"} path="" comments={false} noCollapse={true} />
      <SubsectionsPagePart subsections={content.subsections} lang={lang ?? "en"} path="" />
      <ArticlesTimeline lang={lang ?? "en"} tree_prefix={"/"} />
    </>
  );
}

export default Home;
