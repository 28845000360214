import icon120 from '../images/icon120.png';
import { useLoaderData, useParams } from "react-router-dom";
import { postForm } from "../utils";
import { useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { K, Translator } from "../translations/translator";
import { GenericContent, PostData } from "../CommonComponents";

function About() {
    const {lang} = useParams()
    const t = new Translator(lang)
    const contentData = useLoaderData() as PostData

    const [commentEmail, setCommentEmail] = useState("")
    const [commentContent, setCommentContent] = useState("")
    const [showForm, setShowForm] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [commentInvalid, setCommentInvalid] = useState(false)

    function postComment() {
      if (commentContent.length > 0) {
        const data = new FormData()
        data.append('email', commentEmail)
        data.append('lang', lang ?? "en")
        data.append('article', "/About")
        data.append('comment', commentContent)
        setShowForm(false)
        postForm('/api/comment', data, () => setShowSuccess(true), () => setShowError(true))
      } else {
          setCommentInvalid(true)
      }
    }

    return (
      <div className="Page">
        <GenericContent data={contentData} lang={lang ?? "en"} path="/About" comments={false} />

        <div className="Page-section">
            <img src={icon120} alt="logo" />
        </div>

        <div className="Page-section">
            <h2>{t.t(K.contact_title)}</h2>
            {showForm &&
                <p>{t.t(K.contact_body)}</p>}
            {(showForm || showError) &&
                <Form.Group>
                    <Form.Control aria-label="your message" isInvalid={commentInvalid} disabled={showError} as="textarea" rows={2} placeholder="..." onChange={(e) => setCommentContent(e.target.value)} value={commentContent} />
                </Form.Group>}
            {showForm &&
                <div className="row mt-2 me-0">
                    <Stack gap={3} direction="horizontal" className="col-md-8">
                        <Form.Label className="my-1">{t.t(K.contact_email)}</Form.Label>
                        <Form.Control type="email" placeholder="email@example.com" onChange={(e) => setCommentEmail(e.target.value)} />
                    </Stack>
                    <Button className="col-md-4 my-2" variant='primary' onClick={() => postComment()}>{t.t(K.contact_button)}</Button>
                </div>}
            {showSuccess &&
                <p>{t.t(K.contact_thanks)}</p>}
            {showError &&
                <p>{t.t(K.error)}</p>}
        </div>
      </div>
    );
}
  
export default About;
