import { useLoaderData, useLocation, useParams } from "react-router-dom"
import { ArticlesTimeline, Breadcrumbs, GenericContent, PostData, SubsectionsPagePart } from "../CommonComponents"

export function GenericPage() {
    const data = useLoaderData() as PostData
    const {lang} = useParams()
    const {pathname} = useLocation()
    return (
        <>
            <Breadcrumbs lang={lang ?? "en"} tree_prefix={data.location} />
            <GenericContent data={data} lang={lang ?? "en"} path={pathname} />
            <SubsectionsPagePart subsections={data.subsections} lang={lang ?? "en"} path={pathname} />
            <ArticlesTimeline lang={lang ?? "en"} tree_prefix={data.location} />
        </>
    )
}
